import React,  { useEffect } from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/about-us/AboutUs';
import MyAccount from './pages/my-account/MyAccount';
import RedirectVerifyEmail from './components/RedirectVerifyEmail';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Faq from './pages/faq/Faq';
import Maintenance from './pages/maintenance/Maintenance';
import CompatibleDevices from './pages/compatible-devices/CompatibleDevices';
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from './components/layout/ScrollToTop';
import Footer from './components/layout/Footer';
import BundleDetails from './pages/bundle-details/BundleDetails';
import Paypal from './pages/web-paypal/Paypal';
import Affiliate from './pages/affiliate/Affiliate';
import PaymentSummary from './components/payment/PaymentSummary';
import { useTranslation } from 'react-i18next';
import { getAssetsData } from './services/api';
import { useToast } from './context/ToastContext';
import { messageCatalogActions } from './store/messageCatalog';
import getAssets from "./assets/data/brandingAssets.json";
import { loadGoogleFont } from './utils/loadGoogleFont';


function App() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const general = useSelector(state => state.catalogIds.general);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;
  const { themeColors } = useToast();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();


  const trimColorCode = (colors) => colors ? colors.replace(/"/g, '').trim(): '';

  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeGeneral(response.data))
        let assert =response.data.general
        trimColorCode(assert['GEN0006.defaultPrimaryFont']) === "false" && trimColorCode(assert["GEN0006.fontBase"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontBase"]))
        trimColorCode(assert['GEN0006.defaultSecondaryFont']) === "false" && trimColorCode(assert["GEN0006.fontSecondary"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontSecondary"]))

      } 
    }
    getAssetsData(i18n.language, 'general', getResponse)
  }, [i18n.language, dispatch])


  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeThemeColors(response.data))
      } 
    }
    getAssetsData(i18n.language, 'themeColors', getResponse)
  }, [i18n.language, dispatch]); 

  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeMessageCatalog(response.data))
      } 
    }
    getAssetsData(i18n.language, 'messageCatalog', getResponse)
  }, [i18n.language, dispatch])
  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeLabels(response.data))
      } 
    }
    getAssetsData(i18n.language, 'labels', getResponse)
  }, [i18n.language, dispatch])
  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeValidations(response.data))
      } 
    }
    getAssetsData(i18n.language, 'validations', getResponse)
  }, [i18n.language, dispatch])
  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeMyAccount(response.data))
      } 
    }
    getAssetsData(i18n.language, 'myAccount', getResponse)
  }, [i18n.language, dispatch])  

  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeHeader(response.data))
      } 
    }
    getAssetsData(i18n.language, 'header', getResponse)
  }, [i18n.language, dispatch])


  const defaultPrimaryFont = trimColorCode(general?.['GEN0006.defaultPrimaryFont']);
  const primaryFont = trimColorCode(general?.['GEN0006.fontBase']);
  const fontPrimary  = defaultPrimaryFont === "true" ? getAssets.fonts['font-base'] : primaryFont?? getAssets.fonts['font-base'] ;
  const defaultSecondaryFont = trimColorCode(general?.['GEN0006.defaultSecondaryFont']);
  const secondaryFont = trimColorCode(general?.['GEN0006.fontSecondary']);
  const fontSecondary  = defaultSecondaryFont === "true" ? getAssets.fonts['font-secondary'] : secondaryFont?? getAssets.fonts['font-secondary'] ;
  document.documentElement.style.setProperty('--font-base', fontPrimary);
  document.documentElement.style.setProperty('--font-secondary', fontSecondary);
  document.documentElement.style.setProperty('--general_font_size', getAssets.fonts['general_font_size']);
  document.documentElement.style.setProperty('--white', trimColorCode('#ffffff'));
  document.documentElement.style.setProperty('--black', trimColorCode('#000000'));
  document.documentElement.style.setProperty('--error-msg', trimColorCode('#ff1900'));
  document.documentElement.style.setProperty('--primary', trimColorCode(themeColors.PRIMARY));
  document.documentElement.style.setProperty('--secondary', trimColorCode(themeColors.SECONDARY));
  document.documentElement.style.setProperty('--teritary', trimColorCode(themeColors.TERITARY));
  document.documentElement.style.setProperty('--quaternary', trimColorCode(themeColors.QUATERNARY));
  document.documentElement.style.setProperty('--quinary', trimColorCode(themeColors.QUINARY));
  document.documentElement.style.setProperty('--mainmenu', trimColorCode(themeColors.MAINMENU));
  document.documentElement.style.setProperty('--mainmenuover', trimColorCode(themeColors.MAINMENUOVER));
  document.documentElement.style.setProperty('--btnprimary', trimColorCode(themeColors.BTNPRIMARY));
  document.documentElement.style.setProperty('--btnprimaryover', trimColorCode(themeColors.BTNPRIMARYOVER));
  document.documentElement.style.setProperty('--btnsecondary', trimColorCode(themeColors.BTNSECONDARY));
  document.documentElement.style.setProperty('--btnsecondaryover', trimColorCode(themeColors.BTNSECONDARYOVER));
  document.documentElement.style.setProperty('--input-field', trimColorCode(themeColors.INPUTFIELD));
  document.documentElement.style.setProperty('--input-border', trimColorCode(themeColors.INPUTFIELDBORDER));
  document.documentElement.style.setProperty('--dropdown-bg', trimColorCode(themeColors.DROPDOWNBG));
  document.documentElement.style.setProperty('--dropdown-selected', trimColorCode(themeColors.DROPDOWNSELECTED));


  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <>
            {isUnderMaintenance
              ? <Route index element={<Maintenance />} />
              : <>
                <Route index exact path='/' element={< Home />} />
                <Route path='/about-us' element={< AboutUs />} />
                <Route path='/contact' element={< Contact />} />
                <Route path='/my-account' element={isLoggedIn ? < MyAccount /> : <Navigate to="/" />} />
                <Route path='/verify-email' element={<RedirectVerifyEmail />} />
                <Route path='/update-email' element={<RedirectVerifyEmail />} />
                <Route path='/reset-password' element={<RedirectVerifyEmail />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/bundles/:id' element={<BundleDetails />} />
                <Route path='/paypal' element={<Paypal />} />
                <Route path='/compatible-devices' element={<CompatibleDevices />} />
                <Route path='/affiliate' element={<Affiliate />} />
                <Route path='/paymentresponse' element={<PaymentSummary />} />
                <Route path='*' element={<PageNotFound />} />
              </>
            }
          </>
        </Routes>
        {window.location.pathname !== '/paypal' && <Footer />}
      </Router>
    </>
  );
}

export default App;